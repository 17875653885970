import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Row
} from 'reactstrap';

import '../Main.scss';
import ExcelIcon from '../../images/Excel.png';

import {
    Tooltip
} from '@mui/material';

import { FormatDate, formatStringMDYFullMonth } from '../../Utils/DateUtils';
import { pathConstants } from '../../Utils/Constants';
import {
    AccountingPackageFriendlyNameFromStringDescriptor,
} from '../../Utils/LinkUtils';
import { dataSetNameDescriptor, deleteOrErrorButtonColor, SubmissionMetaBorrowerBusinessEmail, SubmissionMetaBorrowerBusinessName } from '../../Utils/Constants';
import { GetStdThemeColors } from '../../Utils/Style';
import { DownloadableFile, WorkbookToDownloadableFile } from '../../Utils/FileUtils';
import { GetDocTime, GetDocumentURL } from '../../Services/FinancialRecordsService';

import { FinancialsRelinkButton } from '../FinancialsImport/FinancialsRelinkButton';

import { RequestSummary } from '../FileGroup/RequestSummary';

import {
    SubmissionDetail,
    SubmissionSearchEntitySummary,
    SubmissionSearchResult,
    Metadata,
} from '../../Models/Api/strongbox.financialportal';

import { SortableTable, SortableTableColumn, SortableTableSortInformation } from '../SortableTable/SortableTable';

import { SubmissionSearchResultWithDate } from '../../Store/SubmissionHistory';
import { BrandConfig } from '../../Store/Tenant';
import { LinkMode } from '../../Store/ImportFinancials';

import { GroupedFileContainerWithDownload } from '../GroupedFileDropdown/GroupedFileContainerWithDownload';
import { ErrorState } from '../ErrorBanner/ErrorBanner';
import { SubmittedByIcon } from '../SubmittedByIcon/SubmittedByIcon';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlined';
import MappingIcon from '@mui/icons-material/Public';
import RiskAnalysisIcon from '@mui/icons-material/QueryStats';
import UndeleteIcon from '@mui/icons-material/RestoreFromTrash';

export type MostRecentConnectionMap = {
    datasourceNameId: string;
    creationTime: Date;
    submissionId: string;
}

/**
  * @param showActions controls whether the list will show action icon on rows
  * that would have them, for instance, a show workbooks action button for rows that
  * have valid financial information to expand workbooks.  Default is false.
  * @param hideWorkspaceName controls whether the workspace name is shown on 
  * each row. This is unnecessary when the list is targeted at a single workspace but
  * not when showing global submissions.   Default is false
  * */

type Props = {
    actionText: string;
    alwaysCollectBorrowerBusinessInfo?: boolean;
    hasExecutedSearch: boolean;
    showActions?: boolean;
    hideWorkspaceName?: boolean;
    submissions: SubmissionSearchResultWithDate[];
    mostRecentConnections?: MostRecentConnectionMap[];
    details: SubmissionDetail[];
    brandConfig: BrandConfig;
    tableSortInformation: SortableTableSortInformation;
    onSetErrorState: (errorState: ErrorState) => void;
    onSetImporting: (working: boolean) => void;
    working: boolean;
    style?: any;
    onShowDocuments?: (ssr: SubmissionSearchResult) => void;
    onHideDocuments?: (ssr: SubmissionSearchResult) => void;
    onSubmissionClicked?: (submissionId: string) => void;
    onDetailClicked?: (ssr: SubmissionSearchResult, filename: string, fileId: string) => void;
    onSort?: (sortedResults: SubmissionSearchResultWithDate[]) => void;
    onSortParametersChanged?: (sortParameters: SortableTableSortInformation) => void;
    overrideSort?: (sortFunc: (data: SubmissionSearchResultWithDate[]) => SubmissionSearchResultWithDate[]) => SubmissionSearchResultWithDate[];
    financialStatementsEnabled: boolean;
    riskAnalysisEnabled: boolean;
    onRestoreSubmission: (workspaceId: string, submissionId: string) => void;
};

type SubmissionStatusSummary = {
    statusText: string,
    showLink: boolean,
    key: 'deleted' | 'empty' | 'valid' | 'unknown' | 'error',
};

export const SubmissionHistoryTable: React.FC<Props> = (props): React.ReactElement => {
    const {
        alwaysCollectBorrowerBusinessInfo,
        showActions,
        hideWorkspaceName,
        actionText,
        hasExecutedSearch,
        brandConfig,
        mostRecentConnections,
        onDetailClicked,
        onShowDocuments,
        onHideDocuments,
        onSetErrorState,
        onSetImporting,
        onSubmissionClicked,
        onSort,
        overrideSort,
        onRestoreSubmission,
        onSortParametersChanged,
        style,
        submissions,
        details,
        tableSortInformation,
        working,
        financialStatementsEnabled,
        riskAnalysisEnabled,
    } = props;

    const navigate = useNavigate();

    const getSubmissionStatus = (submissionSearchResult: SubmissionSearchResultWithDate): SubmissionStatusSummary => {
        switch (submissionSearchResult.ssr.submission.financialRecordStatus) {
            case 'Deleted':
                return {
                    key: 'deleted',
                    statusText: 'Deleted',
                    showLink: true
                };
            case 'Unknown':
                return {
                    key: 'unknown',
                    statusText: 'Unknown',
                    showLink: true
                };
            case 'ImportError':
                return {
                    key: 'error',
                    statusText: 'Error occurred during data collection',
                    showLink: true
                };
            default:
                if ((!submissionSearchResult.ssr.submission.financialRecordId) ||
                    (!submissionSearchResult.ssr.submission.financialRecordCreated)) {
                    return {
                        key: 'empty',
                        statusText: 'No data available',
                        showLink: true
                    };
                }
                return {
                    key: 'valid',
                    statusText: '',
                    showLink: true
                };
        }
    }

    const compareCreationTime = (ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate): number => {
        const d1 = ssr1.submissionCreationTime;
        const d2 = ssr2.submissionCreationTime;

        if (!(d1 && d2)) {
            // If they're both undefined they're equal.  Else if d1 is undefined it's less than d2
            return (d1 === d2) ? 0 : (!d1 ? -1 : 1);
        }

        return (d1 < d2) ? -1 : ((d1 === d2) ? 0 : 1);
    }

    const compareStringsDefaultToCreationTime = (s1: string | undefined, s2: string | undefined, ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate): number => {
        const compareS1 = s1?.toLowerCase() || '';
        const compareS2 = s2?.toLowerCase() || '';

        if (compareS1 === compareS2) {
            return compareCreationTime(ssr1, ssr2);
        }
        return (compareS1 < compareS2) ? -1 : 1;
    }

    const getEntityName = (entity: SubmissionSearchEntitySummary): string => {
        return `${entity.displayName}${entity.deleted ? ' (deleted)' : ''}`
    }

    const getStatusContent = (rowData: SubmissionSearchResultWithDate, iconStyle: any, submissionStatus: SubmissionStatusSummary): React.ReactElement => {
        const colors = GetStdThemeColors(brandConfig);

        switch (submissionStatus.key) {
            case 'deleted': {
                return (
                    <Tooltip title={'Deleted'}>
                        <DeleteIcon
                            color={'primary'}
                            className={'basic-list-icon'}
                            id={`deleteIcon${rowData.ssr.submission.id}`}
                            onClick={() => { }}
                            style={iconStyle}
                        />
                    </Tooltip>
                );
            }
            case 'error': {
                return (
                    <Tooltip title={'Error during data collection'}>
                        <ErrorIcon
                            color={'primary'}
                            className={'basic-list-icon'}
                            id={`errorIcon${rowData.ssr.submission.id}`}
                            onClick={() => { }}
                            style={{
                                ...iconStyle,
                                color: deleteOrErrorButtonColor,
                            }}
                        />
                    </Tooltip>
                );
            }
            case 'empty': {
                return (
                    <Tooltip title={'No financial data available'}>
                        <span
                            className={'submissions-icon submissions-icon-no-data'}
                            id={`empty${rowData.ssr.submission.id}`}
                            style={{
                                ...iconStyle,
                                background: colors.primaryColor,
                            }}
                        />
                    </Tooltip>

                );
            }
            case 'unknown': {
                return (
                    <Tooltip title={'Unknown'}>
                        <span
                            className={'submissions-icon submission-icon-status-unknown'}
                            id={`unknown${rowData.ssr.submission.id}`}
                            style={{
                                ...iconStyle,
                                background: colors.primaryColor,
                            }}
                        />
                    </Tooltip>
                );
            }
            case 'valid': {
                return (
                    <Tooltip title={'Financial data available'}>
                        <span
                            className={'submissions-icon submissions-icon-data-available'}
                            id={`dataAvailable${rowData.ssr.submission.id}`}
                            style={{
                                ...iconStyle,
                                background: colors.primaryColor,
                            }}
                        />
                    </Tooltip>
                );
            }
        }
        return (<></>);
    }

    const getUndeleteContent = (rowData: SubmissionSearchResultWithDate): React.ReactElement => {
        return (
            <Button
                className={'submissions-button-icon'}
                id={`undelete-submission-button-${rowData.ssr.submission.id}`}
                style={iconStyle}
                onClick={() => {
                    onRestoreSubmission(rowData.ssr.entity.id, rowData.ssr.submission.id);
                }}
            >
                <Tooltip title={'Restore this data collection to visible.'}>
                    <UndeleteIcon
                        color={'secondary'}
                        className={'basic-list-icon'}
                        id={`undelete-submissions-icon-mui-${rowData.ssr.submission.id}`}
                    />
                </Tooltip>
            </Button>
        );
    }

    const navigateToDetails = (urlPath: string, workspaceId: string, submissionId: string): void => {
        navigate(`${urlPath}/${workspaceId}/${submissionId}`);
    }

    const getActionsContent = (rowData: SubmissionSearchResultWithDate, iconStyle: any, submissionStatus: SubmissionStatusSummary): React.ReactElement => {
        if (submissionStatus.key !== 'valid') {
            return (<></>);
        }

        return (
            <React.Fragment>
                {financialStatementsEnabled && (
                    <Button
                        className={'submissions-button-icon'}
                        id={`edit-financial-statements-button-${rowData.ssr.submission.id}`}
                        style={iconStyle}
                        onClick={() => {
                            navigateToDetails(pathConstants.workspaceSubmissionDetailsFinancialStatements, rowData.ssr.submission.entityId, rowData.ssr.submission.id);
                        }}
                    >
                        <Tooltip title={'Edit financial statements for this submission (mapping)'}>
                            <MappingIcon
                                color={'primary'}
                                className={'basic-list-icon'}
                                id={`go-to-financial-statements-icon-mui-${rowData.ssr.submission.id}`}
                            />
                        </Tooltip>
                    </Button>
                )}
                {riskAnalysisEnabled && (
                    <Button
                        className={'submissions-button-icon'}
                        id={`edit-risk-analysis-button-${rowData.ssr.submission.id}`}
                        style={iconStyle}
                        onClick={() => {
                            navigateToDetails(pathConstants.workspaceSubmissionDetailsRiskAnalysis, rowData.ssr.submission.entityId, rowData.ssr.submission.id);
                        }}
                    >
                        <Tooltip title={'Evaluate business risk associated with this submission'}>
                            <RiskAnalysisIcon
                                color={'primary'}
                                className={'basic-list-icon'}
                                id={`go-to-risk-analysis-icon-mui-${rowData.ssr.submission.id}`}
                            />
                        </Tooltip>
                    </Button>
                )}
            </React.Fragment>

        );
    }

    const getDocumentContent = (rowData: SubmissionSearchResultWithDate, iconStyle: any, submissionStatus: SubmissionStatusSummary): React.ReactElement => {
        // We can show details for a submission in 'error' state.  It may or may not have documents associated with it.
        // Do need to be on guard for the case where there are no documents.
        if (!((submissionStatus.key === 'valid') || (submissionStatus.key === 'error'))) {
            return (<></>);
        }

        const showingDocuments =
            (details.findIndex(sub => sub.id === rowData.ssr.submission.id) !== -1);

        return (
            <Button
                className={'submissions-button-icon'}
                id={`docIcon${rowData.ssr.submission.id}`}
                style={iconStyle}
                disabled={working}
                onClick={() => {
                    showingDocuments ?
                        (onHideDocuments && onHideDocuments(rowData.ssr)) :
                        (onShowDocuments && onShowDocuments(rowData.ssr))
                }}
            >
                <Tooltip title={showingDocuments ? 'Hide details' : 'Show data collection details, workbooks and user uploaded files'}>
                    <img
                        src={ExcelIcon}
                        alt={'Workbooks and additional documents'}
                        className={'submissions-icon-excel'}
                    />
                </Tooltip>
            </Button>
        );
    }

    const getRelinkContent = (rowData: SubmissionSearchResultWithDate): React.ReactElement => {
        if (mostRecentConnections) {
            if (!mostRecentConnections.find(cxn => cxn.submissionId === rowData.ssr.submission.id)) {
                return (<></>);
            }
        }

        return (
            <FinancialsRelinkButton
                datasourceNameId={rowData.ssr.submission.datasourceNameId}
                disabled={working}
                onSetErrorState={onSetErrorState}
                onSetImporting={onSetImporting}
                workspaceId={rowData.ssr.entity.id}
                workspaceDisplayName={rowData.ssr.entity.displayName || ''}
                buttonType={'small-button'}
                submissionId={rowData.ssr.submission.id}
            />
        );
    }

    const iconStyle = {
        marginLeft: '3px',
    }

    const getStatusIconColumn = (rowData: SubmissionSearchResultWithDate): React.ReactElement => {
        const submissionStatus = getSubmissionStatus(rowData);
        const statusContent = getStatusContent(rowData, iconStyle, submissionStatus);

        return (
            <div className={'table-icon-column'}>
                <SubmittedByIcon
                    linkType={!rowData.ssr.submissionRequest ? LinkMode.anonymous : rowData.ssr.submissionRequest.hasShareableLink ? LinkMode.shareable : LinkMode.direct}
                    iconId={rowData.ssr.submission.id}
                    iconStyle={iconStyle}
                />
                {statusContent}
            </div>
        );
    }

    const getActionIconColumn = (rowData: SubmissionSearchResultWithDate): React.ReactElement => {
        let actionContent: React.ReactElement = (<></>);

        if (showActions !== true) {
            return actionContent;
        }

        if (!!rowData.ssr.submission.deleted) {
            const undeleteContent = getUndeleteContent(rowData);

            actionContent = (
                <div className={'table-icon-column'}>
                    {undeleteContent}
                </div>
            );
        } else {
            const submissionStatus = getSubmissionStatus(rowData);
            const docContent = getDocumentContent(rowData, iconStyle, submissionStatus);
            const relinkContent = getRelinkContent(rowData);
            const actionsContent = getActionsContent(rowData, { marginLeft: '10px' }, submissionStatus);

            actionContent = (
                <div className={'table-icon-column'}>
                    {docContent}
                    {actionsContent}
                    {relinkContent}
                </div>
            );
        }

        return actionContent;
    }

    /**
     * @param submissionStatus
     * @param rowData
     * @param linkTextOverride if provided overrides the link text.  Default is the submission date if not provided
     */

    const renderSubmissionDetailsLink = (
        submissionStatus: SubmissionStatusSummary,
        rowData: SubmissionSearchResultWithDate,
        linkTextOverride?: string  
    ): React.ReactElement => {
        return (
            <span
                className={(submissionStatus.showLink && !working) ? 'small-link' : ''}
                onClick={() => {
                    if (!working) {
                        if ((!!rowData.ssr.submission.id) && (submissionStatus.showLink)) {
                            onSubmissionClicked && onSubmissionClicked(rowData.ssr.submission.id)
                        }
                    }
                }}
            >
                {!!linkTextOverride ? linkTextOverride : !!rowData.submissionCreationTime ? FormatDate(rowData.submissionCreationTime, formatStringMDYFullMonth) : 'Unknown'}
            </span>
        )
    }

    const tableColumns = React.useMemo(() => {
        const fullTableColumns: SortableTableColumn<SubmissionSearchResultWithDate>[] = [];

        fullTableColumns.push({
            defaultSortOrder: 'none',
            headerTitle: '',
            compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => {
                return 0;
            }),
            getRowData: (rowData: SubmissionSearchResultWithDate) => {
                return getStatusIconColumn(rowData);
            }
        });
        fullTableColumns.push({
            defaultSortOrder: 'descending',
            headerTitle: 'Created',
            compare: compareCreationTime,
            getRowData: (rowData: SubmissionSearchResultWithDate) => {
                const submissionStatus = getSubmissionStatus(rowData);

                return renderSubmissionDetailsLink(submissionStatus, rowData);
            }
        });
        if (hideWorkspaceName !== true) {
            fullTableColumns.push({
                defaultSortOrder: 'ascending',
                headerTitle: 'Workspace name',
                compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => compareStringsDefaultToCreationTime(
                    ssr1.ssr.entity.displayName,
                    ssr2.ssr.entity.displayName,
                    ssr1, ssr2
                )),
                getRowData: (rowData: SubmissionSearchResultWithDate) => {
                    return (
                        <>{getEntityName(rowData.ssr.entity)}</>
                    );
                }
            })
        }
        if (!!alwaysCollectBorrowerBusinessInfo)
        {
            fullTableColumns.push({
                defaultSortOrder: 'none',
                headerTitle: 'Business Information',
                compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => 0),
                getRowData: (rowData: SubmissionSearchResultWithDate) => {
                    if (!rowData.ssr.submission.submissionMetadata) {
                        return (<></>);
                    }

                    const sortedMeta = rowData.ssr.submission.submissionMetadata.sort((m1, m2) => {
                        if (m1.name === m2.name) {
                            return 0;
                        }
                        return (m1.name === SubmissionMetaBorrowerBusinessName) ? -1 : 1;
                    })

                    const metaRows: Metadata[] = [];

                    sortedMeta.forEach(meta => {
                        if (meta.name === SubmissionMetaBorrowerBusinessEmail) {
                            metaRows.push({
                                name: "Email:",
                                value: meta.value,
                                type: meta.type,
                            });
                        } else if (meta.name === SubmissionMetaBorrowerBusinessName) {
                            metaRows.push({
                                name: "Business name:",
                                value: meta.value,
                                type: meta.type,
                            });
                        }
                    });

                    return (
                        <div className={'vertical-list'}>
                            {
                                metaRows.map((meta, i) => {
                                    return (
                                        <div key={`business-information-${rowData.ssr.submission.id}-${i.toString()}`}>
                                            <span className={'header'}>{meta.name}</span>
                                            <span>{meta.value}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    );
                }
            })
        }
        fullTableColumns.push({
            defaultSortOrder: 'ascending',
            headerTitle: dataSetNameDescriptor,
            compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => {
                const s1 = ssr1.ssr.submission.datasetName?.toLowerCase() || '';
                const s2 = ssr2.ssr.submission.datasetName?.toLowerCase() || '';

                if (s1 === s2) {
                    return compareStringsDefaultToCreationTime(
                        ssr1.ssr.entity.displayName,
                        ssr2.ssr.entity.displayName,
                        ssr1, ssr2
                    );
                }
                return (s1 < s2) ? -1 : 1;
            }),
            getRowData: (rowData: SubmissionSearchResultWithDate) => {
                return (
                    <>{rowData.ssr.submission.datasetName}</>
                );
            }
        });
        fullTableColumns.push({
            defaultSortOrder: 'ascending',
            headerTitle: 'Requested by (email)',
            compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => {
                const s1 = (ssr1.ssr.requestingUser && ssr1.ssr.requestingUser.emailAddress?.toLowerCase()) || '';
                const s2 = (ssr2.ssr.requestingUser && ssr2.ssr.requestingUser.emailAddress?.toLowerCase()) || '';

                if (s1 === s2) {
                    return compareStringsDefaultToCreationTime(
                        ssr1.ssr.entity.displayName,
                        ssr2.ssr.entity.displayName,
                        ssr1, ssr2
                    );
                }
                return (s1 < s2) ? -1 : 1;
            }),
            getRowData: (rowData: SubmissionSearchResultWithDate) => {
                return (
                    <>{(rowData.ssr.requestingUser && rowData.ssr.requestingUser.emailAddress) || ''}</>
                );
            }
        });
        fullTableColumns.push({
            defaultSortOrder: 'ascending',
            headerTitle: 'Accounting package',
            compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => {
                const s1 = !!ssr1.ssr.submission.datasourceNameId ? AccountingPackageFriendlyNameFromStringDescriptor(ssr1.ssr.submission.datasourceNameId) : '';
                const s2 = !!ssr2.ssr.submission.datasourceNameId ? AccountingPackageFriendlyNameFromStringDescriptor(ssr2.ssr.submission.datasourceNameId) : '';

                if (s1 === s2) {
                    return compareStringsDefaultToCreationTime(
                        ssr1.ssr.entity.displayName,
                        ssr2.ssr.entity.displayName,
                        ssr1, ssr2
                    );
                }
                return (s1 < s2) ? -1 : 1;
            }),
            getRowData: (rowData: SubmissionSearchResultWithDate) => {
                let datasourceNameId = rowData.ssr.submission.datasourceNameId;

                if (!datasourceNameId) {
                    datasourceNameId = '';
                }

                return (
                    <>{AccountingPackageFriendlyNameFromStringDescriptor(datasourceNameId)}</>
                );
            }
        });
        if (showActions === true) {
            fullTableColumns.push({
                defaultSortOrder: 'none',
                headerTitle: 'Actions',
                compare: ((ssr1: SubmissionSearchResultWithDate, ssr2: SubmissionSearchResultWithDate) => {
                    return 0;
                }),
                getRowData: (rowData: SubmissionSearchResultWithDate) => {
                    return getActionIconColumn(rowData);
                }
            })
        }

        return fullTableColumns;

        // I do not want to include the functions in the dependency array.  That would cause the memoization to be changed
        // on just about every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showActions, submissions, working, hideWorkspaceName])

    const addRowContent = (ssr: SubmissionSearchResultWithDate): React.ReactElement | undefined => {
        const rowDetails = details.find(sub => sub.id === ssr.ssr.submission.id);

        if (!(rowDetails && rowDetails.workbooks)) {
            return undefined;
        }

        const sortedOnRevision = rowDetails.workbooks.sort((wb1, wb2) => {
            const delta = wb1.revisionNumber - wb2.revisionNumber;
            return (delta === 0) ? 0 : delta < 0 ? 1 : -1;
        });

        let latestRevision: number = -1;
        let latestRevisionDate: string = '';

        // If the submission is in the 'error' state it is possible to get here in a state where 
        // there are no associated workbooks.
        
        if (sortedOnRevision.length > 0) {
            latestRevision = sortedOnRevision[0].revisionNumber;
            latestRevisionDate = sortedOnRevision[0].creationTime;
        }

        const downloadable: DownloadableFile[] = [];
        const userUploaded: DownloadableFile[] = [];

        for (let i = 0; i < sortedOnRevision.length; i++) {
            if (sortedOnRevision[i].revisionNumber !== latestRevision) {
                break;
            }
            const sdf = WorkbookToDownloadableFile(
                ssr.ssr.entity.id,
                ssr.ssr.submission.financialRecordId,
                ssr.ssr.submission.financialRecordCreated,
                sortedOnRevision[i]
            );
            if (!!sdf) {
                downloadable.push(sdf);
            }
        }

        if (!!rowDetails.documents && (rowDetails.documents.length > 0)) {
            userUploaded.push({
                filename: 'User uploaded files',
                isDeleted: false,
                id: '',
                separator: true,
            });

            rowDetails.documents.forEach(doc => {
                userUploaded.push({
                    filename: doc.fileName || 'Unnamed file',
                    isDeleted: doc.isDeleted === true,
                    id: doc.id,
                    time: GetDocTime(doc.uploadTime),
                    fileSize: doc.bytes,
                    url: !!doc.id ?
                        GetDocumentURL(
                            ssr.ssr.entity.id,
                            ssr.ssr.submission.id,
                            doc.id
                        ) : ''
                })
            });
        }

        const submissionStatus = getSubmissionStatus(ssr);

        return (
            <>
                {
                    !!ssr.ssr.submission.submissionRequest && (
                        <div
                            style={{
                                boxShadow: 'unset',
                                marginLeft: '20px',
                                marginBottom: '0px'
                            }}
                            className={`content-summary-container`}
                        >
                            <RequestSummary
                                className={'data-container'}
                                requestSummary={ssr.ssr.submission.submissionRequest}
                                loadedUserDisplayName={(ssr.ssr.requestingUser && ssr.ssr.requestingUser.displayName) || ''}
                                submission={ssr.ssr.submission}
                            />
                        </div>
                    )
                }
                {
                    latestRevision !== 1 && latestRevision !== -1 && (
                        <div
                            className={'revision-link-container'}
                        >
                            <span>{'Showing the latest revision. Additional revisions are available in'}&nbsp;</span>
                            {renderSubmissionDetailsLink(submissionStatus, ssr, 'submission details')}
                        </div>
                    )
                }
                { downloadable.length > 0 && (
                    <GroupedFileContainerWithDownload
                        files={{
                            revisionNumber: latestRevision,
                            revisionDate: new Date(latestRevisionDate),
                            supportingDocuments: downloadable.concat(userUploaded)
                        }}
                        fileCount={downloadable.length}
                        ssr={ssr.ssr}
                        onDetailClicked={(filename, fileId) => {
                            onDetailClicked && onDetailClicked(ssr.ssr, filename, fileId);
                        }}
                        closeMeText={'Hide details'}
                        onCloseMe={() => {
                            onHideDocuments && onHideDocuments(ssr.ssr)
                        }}
                    />
                )}
            </>
        )
    }

    const getRowClass = (item: SubmissionSearchResultWithDate): string | undefined => {
        return !!item.ssr.submission.deleted ? 'deleted-submission' : undefined;
    }

    return (
        <Container fluid style={style} className={'content-container'}>
            {!hasExecutedSearch && (
                <Row>
                    <Col>
                        Press '{actionText}' to load requests for financial information
                    </Col>
                </Row>
            )}
            {hasExecutedSearch && submissions && submissions.length === 0 && !working && (
                <Row>
                    <Col>
                        No data collections for this period.
                    </Col>
                </Row>
            )}
            {hasExecutedSearch && submissions && submissions.length > 0 && (
                <Row>
                    <Col>
                        <SortableTable
                            brandConfig={brandConfig}
                            columns={tableColumns}
                            data={submissions}
                            sortInformation={tableSortInformation}
                            overrideSort={overrideSort}
                            onSort={onSort}
                            onSortParametersChanged={onSortParametersChanged}
                            addRowContent={addRowContent}
                            getRowClass={getRowClass}
                        />
                    </Col>
                </Row>
            )}
        </Container>
    );
}
