import { BrandConfig } from './Reducer';
import { BannerMessage, TenantUserMetadataSetting } from '../../Models/Api/strongbox.financialportal';

export enum TenantActions {
    TenantSetResource = 'Tenant/SET_RESOURCE',
    TenantSetBasicSettings = 'Tenant/SET_BASIC_SETTINGS',
    TenantSetBrandConfig = 'Tenant/SET_BRAND_CONFIG',
    TenantSetActiveTenant = 'Tenant/SET_ACTIVE_TENANT',
    TenantListEmailDomains = 'Tenant/LIST_EMAIL_DOMAINS',
    TenantListEmailDomainsComplete = 'Tenant/LIST_EMAIL_DOMAINS_COMPLETE',
    TenantListUserMetadataSettings = 'Tenant/LIST_USER_METADATA_SETTINGS',
    TenantListUserMetadataSettingsComplete = 'Tenant/LIST_USER_METADATA_SETTINGS_COMPLETE',
    TenantGetBannerMessages = 'Tenant/GET_BANNER_MESSAGES',
    TenantGetBannerMessagesComplete = 'Tenant/GET_BANNER_MESSAGES_COMPLETE',
}

export enum ResourceName {
    basicTenantSettings = 'basic-tenant-settings',
    connectTenantDocsUploadPrompt = 'connect-tenant-doc-upload-text',
    connectTenantUploadLink = 'connect-tenant-upload-link-text',
    connectSelectAccountingPkg = 'connect-select-accounting-pkg',
    connectEnterBusinessInfo = 'connect-enter-business-info',
    stylePalette = 'style-palette',
    tenantAuthorizationTimedOut = 'tenant-auth-timeout',
    tenantSlidingWindowTimedOut = 'tenant-auth-sliding-window-timeout',
    tenantWelcomeText = 'tenant-welcome-text',
    tenantReportList = 'tenant-report-list',
    tenantConfirmationText = 'tenant-email-confirmation',
    tenantConfirmingText = 'tenant-email-confirming',
    tenantProgressSteps = 'tenant-progress-steps',
    tenantDownloadUserCopy = 'tenant-download-user-copy',
    tenantDownloadNotExisting = 'tenant-download-not-existing',
    tenantLinkComplete = 'tenant-link-complete',
    tenantLinkCompleteImportOnly = 'tenant-link-complete-import-only',
    tenantLinkCompleteNoActions = 'tenant-link-complete-no-actions',
    tenantLinkReadyToUploadMoreDocs = 'tenant-link-ready-to-upload-more-docs',
    tenantLinkSubmitMoreDocs = 'tenant-link-submit-more-docs',
    tenantCollectFinancialsWarning = 'tenant-collect-financials-warning',
    tenantSelectAccountingSystemSupplemental = 'tenant-select-accounting-system-supplemental',
    tenantBorrowerIntroBannerText = 'tenant-borrower-intro-banner',
    tenantBorrowerParametersBullets = 'tenant-borrower-parameters-bullets',
    tenantShareableLinkExpiredParagraphs = 'tenant-borrower-shareable-link-expired',
    tenantShareableLinkDeletedParagraphs = 'tenant-borrower-shareable-link-deleted',
    tenantDataCollectionGroupDescriptions = 'tenant-data-collection-group-descriptions',
    tenantDataCollectionGroupDescriptionsExcel = 'tenant-data-collection-group-descriptions-excel',
    tenantAdditionalMessagingSelectAccountingDataPage = 'tenant-additional-messaging-acctg-data-page',
};

export enum KnownTenants {
    defaultLender = 'defaultLender',
    defaultAccountant = 'defaultAccountant',
    alvarezandmarsal = 'alvarezandmarsal',
    alvarezandmarsalca = 'alvarezandmarsal-ca',
    alvarezandmarsalfigsnt = 'alvarezandmarsal-figsnt',
    alvarezandmarsalenihealth = 'alvarezandmarsal-enihealth',
    ampleo = 'ampleo',
    andersaudit = 'anders-audit',
    anderscpa = 'anderscpa',
    anglincpaaudit = 'anglincpa-audit',
    aprio = 'aprio',
    armanino = 'armanino',
    armaninobos = 'armanino-bos',
    armaninosfo = 'armanino-sfo',
    asentipartners = 'asentipartners',
    bakertilly = 'bakertilly',
    bennettthrasher = 'bennettthrasher',
    bloomllc = 'bloomllc',
    bmfaudit = 'bmf-audit',
    bmftas = 'bmf-tas',
    bmss = 'bmss',
    bmssaudit = 'bmss-audit',
    bnncpa = 'bnncpa',
    bpmcpa = 'bpmcpa',
    bpmcpaaudit = 'bpmcpa-audit',
    brumbyadvisory = 'brumbyadvisory',
    calumetcapital = 'calumetcapital',
    calvettiferguson = 'calvettiferguson',
    cbh = 'cbh',
    cbcas = 'cbcas',
    cbhdatamigration = 'cbh-datamigration',
    cbizmhm = 'cbizmhm',
    cbizpeadvisory = 'cbiz-peadvisory',
    cck = 'cck',
    cdsdd = 'cdsdd',
    cfgi = 'cfgi',
    chase = 'chase',
    chasesandbox = 'chase-sandbox',
    citrincooperman = 'citrincooperman',
    citrincoopermanassessments = 'citrincooperman-assessments',
    clarknuber = 'clarknuber',
    cohenaudit = 'cohen-audit',
    cohencpa = 'cohencpa',
    cohnreznick = 'cohnreznick',
    cohnreznickgcs = 'cohnreznick-gcs',
    connorgp = 'connorgp',
    convergenceco = 'convergence-co',
    copperpointco = 'copperpointco',
    cpademo = 'cpademo',
    cpademo2 = 'cpademo2',
    creativeplanning = 'creativeplanning',
    creativeplanningaudit = 'creativeplanning-audit',
    cricpa = 'cricpa',
    cshco = 'cshco',
    deandorton = 'deandorton',
    demo = 'demo',
    eidebaillytas = 'eidebailly-tas',
    eidebaillycas = 'eidebailly-cas',
    eidebaillyvaluation = 'eidebailly-val',
    elliottdavisdatamigration = 'elliottdavis-datamigration',
    elliottdavisclientassessment = 'elliottdavis-clientassessment',
    elliottdavismna = 'elliottdavis-mna',
    embarkwithus = 'embarkwithus',
    ernstyoung = 'ernstyoung',
    ernstyoungaustralia = 'ernstyoungaustralia',
    ernstyoungcanada = 'ernstyoungcanada',
    exbogroup = 'exbogroup',
    eyusfso = 'ey-usfso',
    eyustmt = 'ey-ustmt',
    eyusgps = 'ey-usgps',
    eyusindustrialsenergy = 'ey-usindustrialsenergy',
    eyusconsumerhealth = 'ey-usconsumerhealth',
    eyus = 'ey-us',
    eylatam = 'ey-latam',
    eysg = 'ey-sg',
    eytesting = 'ey-testing',
    eypl = 'ey-pl',
    eyde = 'ey-de',
    eynl = 'ey-nl',
    eyuki = 'ey-uki',
    firethornadvisory = 'firethornadvisory',
    fivetwelvefinancial = 'fivetwelvefinancial',
    frp = 'frp',
    gbqaudit = 'gbq-audit',
    gbqtas = 'gbq-tas',
    greerwalkerTASAudit = 'greerwalker-tasaudit',
    greerwalkercas = 'greerwalkercas',
    gt = 'gt',
    guardianduediligence = 'guardianduediligence',
    h2advisors = 'h2advisors',
    highrock = 'highrock',
    houlihanlokey = 'houlihanlokey',
    infiniteadvisors = 'infinite-advisors',
    intraprise = 'intraprise',
    intrinsic = 'intrinsic',
    ironsidesadv = 'ironsidesadv',
    ispn = 'ispn',
    jtaylor = 'jtaylor',
    kahnlitwin = 'kahnlitwin',
    kalosllp = 'kalosllp',
    kanter = 'kanter',
    kanterassessments = 'kanter-assessments',
    klraudit = 'klr-audit',
    klrassessments = 'klr-assessments',
    klroutsourcing = 'klroutsourcing',
    kmcoaudit = 'kmco-audit',
    kmtas = 'km-tas',
    kroll = 'kroll',
    laniganryanassessments = 'laniganryan-assessments',
    lbmctas = 'lbmc-tas',
    lbmcwsquared = 'lbmcw-squared',
    leasecrunch = 'leasecrunch',
    mainsailpartners = 'mainsailpartners',
    marcum = 'marcum',
    marcumtasne = 'marcum-tasne',
    midcapadvisors = 'midcapadvisors',
    millercooper = 'millercooper',
    millercoopercas = 'millercooper-cas',
    millerkaplanaudit = 'millerkaplan-audit',
    mlrpc = 'mlrpc',
    mlrpcaudit = 'mlrpc-audit',
    modventuresllc = 'modventuresllc',
    msllc = 'msllc',
    numericacu = 'numericacu',
    oakbridgeas = 'oakbridgeas',
    onedigital = 'onedigital',
    palmtreellc = 'palmtreellc',
    pennsaxton = 'pennsaxton',
    pennsaxtonAudit = 'pennsa-audit',
    pilot = 'pilot',
    pkfod = 'pkfod',
    plantemoran = 'plantemoran',
    plantemoranaudit = 'plantemoran-audit',
    pppllc = 'pppllc',
    prodqa = 'prod-qa',
    redpathcpas = 'redpathcpas',
    redridgeds = 'redridgeds',
    revenuerocket = 'revenuerocket',
    riveron = 'riveron',
    rkl = 'rkl',
    rklesolutionsdata = 'rklesolutions-data',
    rklvirtualassessments = 'rklvirtual-assessments',
    rklvirtualoutsourcing = 'rklvirtual-outsourcing',
    rsm = 'rsm',
    rubinbrown = 'rubinbrown',
    sevlaser = 'sevlaser',
    sikich = 'sikich',
    smithhoward = 'smithhoward',
    smithhowardaudit = 'smithhoward-audit',
    smithleonard = 'smith-leonard',
    sockeye = 'sockeye',
    ssosandbox = 'sso-sandbox',
    stoutara = 'stout-ara',
    stoutta = 'stout-ta',
    strongbox = 'strongbox',
    sunveragroup = 'sunveragroup',
    svb = 'svb',
    sweeneyconradreporting = 'sweeneyconrad-reporting',
    sweeneyconradcas = 'sweeneyconrad-cas',
    swktech = 'swktech',
    tanner = 'tanner',
    temp_test = 'temp_test',
    tggaccounting = 'tggaccounting',
    thinkbrg = 'thinkbrg',
    threepgtas = 'threepg-tas',
    tjtaudit = 'tjt-audit',
    tjtpa = 'tjtpa',
    torpago = 'torpago',
    trialta = 'trialta',
    troutcapital = 'troutcapital',
    trouttas = 'trout-tas',
    vmg = 'vmg',
    warrenaverett = 'warrenaverett',
    warrenaverettaudit = 'warrenaverett-audit',
    weaveraudit = 'weaver-audit',
    weavertas = 'weaver-tas',
    whitleypenn = 'whitleypenn',
    williamsmarston = 'wmarston',
    windes = 'windes',
    windhambrannon = 'windhambrannon',
    windhambrannoncas = 'windhambrannon-cas',
    windhambrannonassessments = 'windhambrannon-assessments',
    wipfli = 'wipfli',
    withum = 'withum',
    wolfandcooas = 'wolfandco-oas',
    wolfandcoaudit = 'wolfandco-audit',
    wolterskluwer = 'wolterskluwer'
};

export type TenantPaletteItem = {
    name: string,
    page: string,
    value: any,
};

export type TenantPalette = {
    paletteColletion: Record<string, TenantPaletteItem>,
};

export enum PaletteItemNames {
    h1Login = 'h1-login',
}

export type TenantParagraphSet = {
    paragraphs: string[];
}

export type TenantParagraphAttribute = {
    name: string;
    value: string;
}

export type TenantAttributedParagraphSet = {
    paragraphs: {
        attributes: TenantParagraphAttribute[],
        content: string;
    }[]
}

export type TenantLineItemWithImage = {
    img: (className?: string) => React.ReactElement;
    text: string;
}

export type TenantLineItemImageSet = {
    lineItems: TenantLineItemWithImage[];
}

export type TenantLoginDestination = {
    id: string;
    name: string;
    url: string;
    tenantName: string;
    // if buttonText is present, this text will be used on the login button for
    // the tenant, otherwise just the name will be used.
    buttonText?: string;
}

export type TenantLoginText = {
    bold?: boolean;
    fontSize?: number; // value in pix
    bottomMargin?: number;  // value in pix
    // Straight up content for a paragraph of text.  There is one special delimiter.
    // If content contains one or more instances of '<team>' that delimiter will 
    // be replaced with the name of the team associated with the portal to which they
    // are signing in.
    content: string;   
}

export type MultiTenantSettings = {
    // If defaultLogin is undefined or true, a login button will be shown on
    // the main screen and no selection button will be presented for the team portal
    // to which the user is signing in.  Presumably, some welcome text (see how that's defined)
    // will contain the name of the team to which they're signing in.
    defaultLogin?: boolean;

    welcomeText?: TenantLoginText[];

    // If set, placeLoginAfterWelcomeLine will place the login button after this line
    // from the welcome text.  If this is 0, the button is at the top.   If 
    // defaultLogin is false, this has no effect.
    placeLoginAfterWelcomeLine?: number;

    showSeparatorAfterWelcomeText?: boolean;
    passLoginThrough?: boolean;
    loginDestinations: TenantLoginDestination[];
}

export type TenantFlowDisabledContent = {
    paragraphs: TenantLoginText[];
}

export type TenantDisable = {
    lender?: TenantFlowDisabledContent;
    borrower?: TenantFlowDisabledContent;
}

/**
 * @member { Record<string, string> } redirections - allows for redirecting specific pages under a tenant. 
 * The first value in the record, the key is the pathname, e.g. '/portal' and the second value is the
 * target, e.g. 'notfound'.  In this example portal is redirected to notfound.
 * */

export type TenantBasicSettings = {
    background?: string;
    connectWelcomeMessage: string;
    defaultResource: KnownTenants.defaultLender | KnownTenants.defaultAccountant;
    favIconTitle: string;
    showStrongboxLogo: boolean;
    visibleName: string;
    redirections?: Record<string, string>;
    TOULink?: string;
    StrongboxSecurityInfoLink?: string;
    multiTenantSettings?: MultiTenantSettings;
    tenantDisable?: TenantDisable;
}

export type TenantResource = {
    tenantName: KnownTenants;
    resource: string | TenantPalette | TenantParagraphSet | NodeRequire | TenantLineItemImageSet | TenantAttributedParagraphSet;
}

export type TenantResourceList = {
    resourceName: ResourceName;
    resources: TenantResource[];
}

export interface ISetResource {
    readonly type: TenantActions.TenantSetResource;
    readonly list: TenantResourceList;
}

export interface ISetActiveTenant {
    readonly type: TenantActions.TenantSetActiveTenant;
    readonly tenant: string;
}

export interface ISetBasicTenantSettings {
    readonly type: TenantActions.TenantSetBasicSettings;
    readonly settings: TenantBasicSettings;
}

export interface ISetBrandConfig {
    readonly type: TenantActions.TenantSetBrandConfig;
    readonly brandconfig: BrandConfig;
}

export interface IListEmailDomains {
    type: TenantActions.TenantListEmailDomains;
}

export interface IListEmailDomainsComplete {
    type: TenantActions.TenantListEmailDomainsComplete;
    domains: string[];
}

export interface ITenantListUserMetadataSettings {
    type: TenantActions.TenantListUserMetadataSettings;
}

export interface ITenantListUserMetadataSettingsComplete {
    type: TenantActions.TenantListUserMetadataSettingsComplete;
    metadataSettings: TenantUserMetadataSetting[];
}

export interface IGetBannerMessage {
    type: TenantActions.TenantGetBannerMessages;
}

export interface IGetBannerMessageComplete {
    type: TenantActions.TenantGetBannerMessagesComplete;
    messages: BannerMessage[];
}

export type KnownAction =
    ISetResource |
    ISetActiveTenant |
    ISetBasicTenantSettings |
    ISetBrandConfig |
    IListEmailDomains |
    IListEmailDomainsComplete |
    ITenantListUserMetadataSettings |
    ITenantListUserMetadataSettingsComplete |
    IGetBannerMessage |
    IGetBannerMessageComplete;
